
export default {
    name: 'BagTitle',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object,
        edit: Object,
        goods: Object
    },
    data() {
        return {
            rsInfo: {
                onSelect: true,
                orgOnSelect: true,
                editBlockSelect: false,
            },
            active: {},
            params: {
                invalid: ''
            },
            addShow:false,
            activityList:[],
            simpleSpec(item){
                let spec = []
                item.map(res=>{
                    spec.push(res.specVal)
                })
                return spec.join('/')
            },
            select: {
                visible: false,
                product: {},
                productCode:'',
                options: {
                    hasCusJump: false,
                    hasImdAddBag: false,
                    btnText:this.$translate('Confirm'),
                    hasDetailInfo:true,
                    hasQuantity:false
                }
            },
            bundleInfo:{}
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        },
        edit: {
            handler() {
                this.setEditData()
            },
            deep: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 加购
        setAddAjax(params) { // 加购接口
            this.$api.product.setAddBag({
                data: JSON.stringify(params)
            }).then(response => {
                this.middleEmit.fn('getBagAjax')
                setTimeout(() => {
                    this.select.visible = false
                    this.addShow = false
                }, 50)
            })
        },
        setAdd() {
            let params = [];
            let isBundle = this.activityList.find(res=>{return res.hasSelectProduct})
            if(!isBundle) return
            this.activityList.map(res=>{
                if(res.hasSelectProduct){
                    params.push({
                        skuId: res.skuId,
                        spuId: res.id,
                        quantity: 1,
                        customizeType: 1,
                        isVipService: false
                    })
                }
            })
            this.setAddAjax(params)
        },
        emitSuccess(obj){
            this.activityList.map(item=>{
                if(item.productCode==obj.sku.select.spuCode){
                    item.hasSelectProduct = true
                    item.skuId = obj.sku.select.id
                    item.displayOrgPrice = obj.sku.select.displayOrgPrice
                    item.displaySalePrice = obj.sku.select.displaySalePrice
                    item.skuPic = obj.sku.select.skuPic
                }
            })
            this.bundleChange()
            setTimeout(() => {
                this.select.options.visible = false
                this.select.visible = false
            }, 50)
        },
        bundleChange(){
            let isBundle = this.activityList.find(res=>{return res.hasSelectProduct})
            if(!isBundle) {
                this.activityList.map(res=>{
                    res.displaySalePrice = res.displayOrgPrice
                })
                this.bundleInfo.useCurrencyBundleDiscountPrice = 0
                return
            }
            let spuItems = [{
                spuId:this.rsInfo.cartItems[0].spuId,
                skuId:this.rsInfo.cartItems[0].skuId,
                thisGoods:true
            }]
            this.activityList.map(res=>{
                if(res.hasSelectProduct){
                    spuItems.push({
                        skuId: res.skuId,
                        spuId: res.id,
                        thisGoods:false
                    })
                }else{ //把没有匹配的价格还原
                    res.displaySalePrice = res.displayOrgPrice
                }
            })
            
            this.$api.product.setBundleCalc({
                data: JSON.stringify({
                    activityId: this.rsInfo.activityId,
                    spuItems: spuItems
                })
            }).then(res=>{
                this.bundleInfo = res.result
                this.bundleInfo.bundleAssists.map(list=>{
                    this.activityList.map(list2=>{
                        if(list.skuId==list2.skuId){
                            list2.displayOrgPrice = list.displayOrgPrice
                            list2.displaySalePrice = list.displaySalePrice
                        }
                    })
                })
            })
        },
        emitClose(){
            setTimeout(() => {
                this.select.visible = false
            }, 50)
        },
        productClick(res,index){
            if(res.hasSelectProduct){
                res.hasSelectProduct = false
                this.bundleChange()
            }else{
                if(res.skus.length > 1){
                    this.select.product = res
                    this.select.productCode = res.productCode
                    this.clear && clearTimeout(this.clear)
                    this.clear = setTimeout(() => {
                        this.select.options.visible = true
                        this.select.visible = true
                    }, 50)
                }else{
                    res.skuId = res.skus[0].id
                    res.hasSelectProduct = true
                    this.bundleChange()
                }
            }
            this.$forceUpdate()
        },
        openProInfo(res){
            this.select.product = res
            this.select.productCode = res.productCode
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.select.options.visible = true
                this.select.visible = true
            }, 50)
        },
        setData() {
            // goodsType -> 0 下架商品 1 普通商品 2 满赠商品 3 赠品 4 闪购商品 5 满折商品 8 满减活动 11 新人专享 12 礼品卡
            const rsInfo = JSON.parse(JSON.stringify(this.info));
            this.rsInfo = {...rsInfo}
            this.active = this.rsInfo.activityAmt || this.rsInfo.flashActivity || this.rsInfo.bmoActivity || this.rsInfo.reducedActivity || this.rsInfo.newBindActivity || {}
            this.active.hasActive = !!this.active.activityId
            this.active.hasTime = this.active?.endsIn > 0
            if(!this.active.activityName) {
                this.active.activityName = this.$translate('Flash Sale')
            }
            this.setSelectData()
        },
        setSelectData() {
            this.rsInfo.onSelect = true
            const list = this.rsInfo.cartItems || [];
            const idArr = [];
            list.forEach(p => {
                if(!p.onSelect) {
                    this.rsInfo.onSelect = false
                }
                idArr.push(p.id)
            })
            this.rsInfo.orgOnSelect = this.rsInfo.onSelect
            this.rsInfo.rsId = idArr.join(',')
        },
        handleChoose() {
            this.rsInfo.onSelect = !this.rsInfo.onSelect
            this.$fnApi.debounce(() => {
                if(this.rsInfo.onSelect === this.rsInfo.orgOnSelect) return
                this.middleEmit.fn('setChooseProduct', {
                    id: this.rsInfo.rsId,
                    onSelect: this.rsInfo.onSelect
                })
            }, 300)
        },
        // 倒计时结束
        finishTime() {
            this.middleEmit.fn('getBagAjax')
        },
        handleActive() {
            if(this.rsInfo.goodsType==13){
                if(this.active.discountAmount) return
                this.addShow = true
                this.$api.product.findBindGroupGoods({
                    activityId:this.rsInfo.activityId,
                    spuCode:this.rsInfo.cartItems[0].productCode,
                }).then(res=>{
                    let activityList = res.result
                    activityList.map(list=>{
                        list.simpleSpec = list.skus[0].specs
                    })
                    this.activityList = activityList
                })
                return
            }
            // const url = {
            //     2: 'Activity-FreeGifts',
            //     4: 'Activity-FlashSale',
            //     5: 'Activity-FullFold',
            //     8: 'Activity-FullReduce',
            //     11: 'Activity-NewComer',
            // };
            // const name = url[this.rsInfo.goodsType];
            this.$router.push({
                // name,
                name: 'Activity-Tmpl',
                params: {
                    seoUrl: this.rsInfo?.bmoActivity?.seoUrl||this.rsInfo?.activityAmt?.seoUrl||this.rsInfo?.seoUrl
                },
                // query: {
                //     activityId: this.rsInfo.activityId
                // }
            })
        },
        // 购物车编辑--------------------
        setEditData() {
            if(!this.edit.hasEdit) return
            const idArr = this.rsInfo.rsId.split(',');
            let editBlockSelect = true;
            // 当前板块选择
            idArr.forEach(p => {
                if(!this.edit.id.includes(p)) {
                    editBlockSelect = false
                }
            })
            this.rsInfo.editBlockSelect = editBlockSelect
            // 是否全选
            this.setEditAllSelect()
        },
        setEditAllSelect() {
            let allIdArr = [];
            let editAllSelect = true;
            this.goods.list.forEach(p => {
                p.cartItems.forEach(c => {
                    allIdArr.push(c.id)
                })
            })
            allIdArr.forEach(p => {
                if(!this.edit.id.includes(p)) {
                    editAllSelect = false
                }
            })
            this.edit.hasAllSelect = editAllSelect
        },
        handleEditChoose() {
            const idArr = this.rsInfo.rsId.split(',');
            const editIdArr = this.edit.id.split(',');
            let newEditArr = [...editIdArr];
            !this.edit.id && (newEditArr = [])
            this.rsInfo.editBlockSelect = !this.rsInfo.editBlockSelect
            if(this.rsInfo.editBlockSelect) {
                idArr.forEach(p => {
                    if(!editIdArr.includes(p)) {
                        newEditArr.push(p)
                    }
                })
            } else {
                newEditArr = editIdArr.filter(p => {
                    return !idArr.includes(p)
                })
            }
            this.edit.id = newEditArr.join(',')
        },
        // set params
        setParams() {
            const list = this.rsInfo.cartItems || [];
            const initialArrId = [];
            list.forEach(p => {
                initialArrId.push(p.id)
            })
            this.params.invalid = initialArrId.join(',')
        },
        // 删除购物车商品
        deleteProductAjax() {
            this.$api.bag.deleteBag({
                ids: this.params.invalid
            }).then(response => {
                this.middleEmit.fn('getBagAjax')
            })
        },
        // 失效商品
        handleDeleteInvalid() {
            this.setParams()
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to delete this item(s)')}?`,
                confirmButtonText: this.$translate('Delete'),
                cancelButtonText: this.$translate('Cancel')
            }).then(() => {
                this.deleteProductAjax()
            }).catch(() => {})
        },
    },
}
