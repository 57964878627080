
export default {
    name: 'BagGiftList',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            visible: false,
            listCompo: { // 列表组件配置
                list: [],
                options: {
                    hasMore: true,
                }
            },
            page: {
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            },
            categoryName:''
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getRecAjax()
    },
    methods: {
        getRecAjax() {
            this.$api.category.getCategoryList({
                pageSize: 20,
                pageNum: 1,
                cateSeoUrl: 'greeting-card-gift-box'
            }, false).then(response => {
                let res = response.result || {}
                let list = res.list || []
                list.forEach(p => {
                    const spuModel = p.spuModel || {}
                    Object.keys(spuModel).forEach(key => {
                        p[key] = spuModel[key]
                    })
                })
                this.categoryName = res.categoryName
                this.listCompo.list = [...this.listCompo.list, ...list]
                this.totalPage = res.totalPage
                this.visible = this.listCompo.list.length
            })
        },
        emitAddBag() {
            this.middleEmit.fn('getBagAjax')
        },
        emitBottomLoading(e, callBack) {
            const hasNext = this.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                this.getRecAjax()
            }
            callBack(!hasNext)
        }
    },
}
